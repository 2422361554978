var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" },
              [
                _c("c-dept", {
                  attrs: {
                    type: "search",
                    isFirstValue: false,
                    name: "deptCd",
                  },
                  model: {
                    value: _vm.searchParam.deptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "deptCd", $$v)
                    },
                    expression: "searchParam.deptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    isFirstValue: false,
                    name: "userId",
                    label: "사용자",
                  },
                  model: {
                    value: _vm.searchParam.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "userId", $$v)
                    },
                    expression: "searchParam.userId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "사용자별 유해인자 목록",
                merge: _vm.grid.merge,
                columns: _vm.grid.columns,
                selection: "multiple",
                rowKey: "userId",
                data: _vm.grid.data,
                editable: _vm.editable,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "hazardNames"
                        ? [
                            _c("q-btn", {
                              staticClass: "tab-add-btn",
                              attrs: {
                                rounded: "",
                                size: "xs",
                                color: "orange-6",
                                icon: "add",
                                label: "추가",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addHazard(props.row)
                                },
                              },
                            }),
                            _vm._v("   "),
                            _vm._l(
                              props.row.hazardList,
                              function (hazard, index) {
                                return _c(
                                  "q-chip",
                                  {
                                    key: index,
                                    staticClass: "q-ma-none customchipdept",
                                    staticStyle: { top: "3px" },
                                    attrs: {
                                      outline: "",
                                      square: "",
                                      removable: _vm.editable,
                                      color: "primary",
                                      "text-color": "white",
                                    },
                                    on: {
                                      remove: function ($event) {
                                        return _vm.removeHazard(
                                          props.row,
                                          hazard
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(hazard.hazardName) + " "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ]
                        : void 0,
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "suffixTitle" },
                [
                  _c(
                    "font",
                    {
                      staticStyle: {
                        "font-size": "0.8em",
                        "font-weight": "300",
                      },
                      attrs: { color: "#C10015" },
                    },
                    [
                      _vm._v(
                        " ※ 사용자 등록하면 부서(반)별 유해인자가 자동으로 등록됩니다. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addDept },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.removeDept },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        attrs: { label: "LBLSEARCH", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }